




















































































































































import { Component, Vue } from "vue-property-decorator";
import { CallNumber } from "@ionic-native/call-number";

import App from "@/app/modules/_global/classes/App";
import GA from "@/app/modules/_global/classes/Analytics";
import Connection from "@/app/modules/_global/classes/Connection";

const NetworkConnection = new Connection();

@Component({
  components: {
    Snackbar: () => import("@/app/modules/_global/components/Snackbar.vue")
  }
})
export default class AuthLayout extends Vue {
  devModeClicks: number = 0 as number;
  devModeTimeout: number = 0 as number;

  emergencyDialog: boolean = false as boolean;

  get toolbarTitle() {
    return this.$t(this.$route.meta?.title);
  }

  get appData() {
    return this.$store.getters["app/data"];
  }

  get appLayout() {
    try {
      return this.appData.layout;
    } catch (e) {
      return null;
    }
  }

  get deviceInfo() {
    return this.$store.getters["device/deviceInfo"];
  }

  get loginScreenImage() {
    if (this.appData.login_screen_image == null) return null;

    let url = process.env.VUE_APP_CORE_URL;

    if (this.appMode == "Dev") url = process.env.VUE_APP_CORE_DEV_URL;
    if (this.appMode == "Test") url = process.env.VUE_APP_CORE_TEST_URL;

    return `${url}/storage/${this.appData.login_screen_image}`;
  }

  get appLanguages() {
    return this.appData.languages;
  }

  get appLanguage() {
    return this.$store.getters["app/language"];
  }

  set appLanguage(locale) {
    this.$store.dispatch("app/changeLanguage", locale).then(({ data }) => {
      App.init({ auth: false });
      App.setLocale(locale, data);
    });
  }

  get envAppMode() {
    return process.env.VUE_APP_MODE;
  }

  get appMode() {
    return this.$store.getters["app/mode"];
  }

  set appMode(mode) {
    if (mode == "Prod") this.resetAppMode();
    else this.setAppMode(mode);
  }

  beforeDestroy() {
    NetworkConnection.removeListener();
  }

  enterDevMode() {
    if (this.devModeClicks < 10) {
      this.devModeClicks++;

      clearTimeout(this.devModeTimeout);

      this.devModeTimeout = window.setTimeout(() => {
        if (this.devModeClicks < 10) {
          this.resetAppMode();
          this.devModeTimeout = 0;
        }
      }, 1000);
    }

    if (this.devModeClicks == 10) {
      if (this.appMode != "Dev") this.setAppMode("Dev");
    }
  }

  setAppMode(mode: string) {
    this.$store.commit("app/setMode", mode);
    GA.addCustomDimension(4, mode);
  }

  resetAppMode() {
    this.devModeClicks = 0;
    this.setAppMode(this.envAppMode ?? "Prod");
  }

  bottomNavigationHas(item: string): boolean {
    return this.appData.bottom_navigation?.includes(item);
  }

  callMedicall() {
    CallNumber.callNumber("0848111811", true)
      .then(res => console.log("Launched dialer!", res))
      .catch(err => console.log("Error launching dialer", err));
  }
}
