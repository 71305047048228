import { LocaleMessageObject } from "vue-i18n";
import i18n from "@/i18n";
import store from "@/app/store";
import vuetify from "@/plugins/vuetify";

// Import the unique bundle id
import { appId } from "@/../capacitor.config.json";

// Classes
import Device from "@/app/modules/_global/classes/Device";
import Vuetify from "@/app/modules/_global/classes/Vuetify";

// Google Analytics
import GA from "./Analytics";

export default class App {
  static device: Device = new Device();

  static async init({ auth = false }): Promise<void> {
    const route: string = auth ? "auth/initAuth" : "auth/initApp";

    const deviceInfo = await this.device.getDeviceInfo();
    const deviceLanguage = await this.device.getLanguageCode();
    const appLanguage = store.getters["app/language"];
    const appMode = store.getters["app/mode"];

    if (deviceInfo.platform != "web") {
      const PROD_TRACKING_ID = "UA-51170298-2"; // https://analytics.google.com/analytics/web/#/report-home/a51170298w253370388p233277239
      const TEST_TRACKING_ID = "UA-179632984-1"; // https://analytics.google.com/analytics/web/#/report-home/a179632984w248296445p230344323

      await GA.startTrackerWithId(appMode == "Prod" ? PROD_TRACKING_ID : TEST_TRACKING_ID);
    }

    return new Promise((resolve, reject) => {
      store
        .dispatch(route, {
          bundle: appId,
          language: appLanguage ?? deviceLanguage.value,
          device: deviceInfo
        })
        .then(({ data }) => {
          store.commit("app/setData", data.app);
          store.commit("profile/setSalutations", data.salutations ?? []);

          if (auth) {
            if (deviceInfo.platform != "web") {
              GA.setUserId(`${data.user_data.user.id}`);
              GA.addCustomDimension(2, data.building_complex?.business_customer?.name);
              GA.addCustomDimension(3, data.building_complex?.name);
              GA.addCustomDimension(4, store.getters["app/urlMode"]);
              GA.addCustomDimension(5, data.user_data.person.id);
            }

            store.commit("profile/setData", data.user_data);
            store.commit(
              "tags/setData",
              data.user_data.tags.map((tag: { id: number }) => tag.id)
            );
            store.commit("news/setData", data.user_data.notifications);
            store.commit("app/setBuildingComplexData", data.building_complex);
          }

          const allowedLanguages = data.app.languages.map((el: { locale: string }) => el.locale);
          const defaultLanguage = data.app.default_language.locale;

          let locale: string;

          if (appLanguage != null && allowedLanguages.includes(appLanguage)) {
            locale = appLanguage;
          } else if (allowedLanguages.includes(deviceLanguage.value)) {
            locale = deviceLanguage.value;
          } else {
            locale = defaultLanguage;
          }

          this.setLocale(locale, data.translations);

          new Vuetify(data.app.layout);

          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  static setLocale(locale: string, data: LocaleMessageObject): void {
    i18n.setLocaleMessage(locale, data);
    i18n.locale = locale;
    vuetify.framework.lang.current = locale;

    store.commit("app/setLanguage", locale);
  }
}
