import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { DeviceInfo } from "@capacitor/core";
import Api from "@/app/modules/_global/classes/api/Api";

@Module({ namespaced: true })
export default class DeviceStore extends VuexModule {
  private _deviceInfo: null | DeviceInfo = null;

  get deviceInfo(): null | DeviceInfo {
    return this._deviceInfo;
  }

  @Mutation
  setDeviceInfo(data: DeviceInfo): void {
    this._deviceInfo = data;
  }

  @Action
  fetchCurrent(data: string) {
    return Api.get(`/api/profile/device/${data}`);
  }

  @Action
  updateFcmTokenActive(data: { id: number }) {
    return Api.patch(`/api/profile/fcm-token-active/${data.id}`, data);
  }
}
