import { DeviceInfo, Plugins, NotificationPermissionResponse } from "@capacitor/core";
import { appId } from "@/../capacitor.config.json";
import { Badge } from "@ionic-native/badge";
import router from "@/app/router";
import store from "@/app/store";
import moment from "moment";

const { PushNotifications } = Plugins;

export default class Push {
  static requestPermission(): Promise<NotificationPermissionResponse> {
    return PushNotifications.requestPermission();
  }

  static register() {
    PushNotifications.register();
  }

  static async registrationListener(deviceInfo: DeviceInfo, appName: string) {
    if (deviceInfo.appId != appId) deviceInfo.appId = appId;

    await this._setBadge();

    PushNotifications.addListener("registration", token => {
      store.dispatch("profile/updateAppDevice", {
        appDevice: {
          ...deviceInfo,
          app: appName,
          fcm_token: token.value
        }
      });
    });
  }

  static registrationErrorListener(): void {
    // TODO: action, when registration error occurred
    // PushNotifications.addListener("registrationError", error => {
    //   alert(`Error on registration: ${JSON.stringify(error)}`);
    // });
  }

  static actionPerformedListener(): void {
    // The action, when the person clicks on the push
    PushNotifications.addListener("pushNotificationActionPerformed", action => {
      this._setBadge().then(() => {
        if (action.notification.data.route == "booking") {
          router.push({
            name: "booking.reservations.edit",
            params: { id: action.notification.data.id }
          });
        }

        // TODO: Add news 'data' to the core controller
        else {
          store.dispatch("news/fetch").then(({ data }) => {
            store.commit("news/setData", data.data);

            if (router.currentRoute.name != "news") {
              router.push({ name: "news", params: { from: "push" } });
            }
          });
        }
      });
    });
  }

  static receivedListener(): void {
    // The action, when the person gets the push during the app is open
    PushNotifications.addListener("pushNotificationReceived", notification => {
      if (
        notification.data.route == "booking" &&
        router.currentRoute.name == "booking.reservations.edit"
      ) {
        router.replace({
          name: "booking.reservations.edit",
          params: { id: notification.data.id },
          query: { push_received_at: moment().toISOString() }
        });
      }
    });
  }

  static removeAllListeners(): void {
    PushNotifications.removeAllListeners();
  }

  private static async _setBadge(): Promise<void> {
    await new Promise<void>(resolve => {
      PushNotifications.getDeliveredNotifications().then(list => {
        list.notifications.length ? Badge.set(list.notifications.length) : Badge.clear();
        resolve();
      });
    });
  }
}
