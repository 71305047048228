import { NetworkStatus, Plugins, PluginListenerHandle } from "@capacitor/core";
const { Modals, Network } = Plugins;

export default class Connection {
  private handler: PluginListenerHandle;

  constructor() {
    this.handler = Network.addListener("networkStatusChange", status => this.handleStatus(status));
    this.networkStatus().then(status => this.handleStatus(status));
  }

  async networkStatus(): Promise<NetworkStatus> {
    return await Network.getStatus();
  }

  private handleStatus(status: NetworkStatus) {
    if (!status.connected) this.showAlert();
  }

  private async showAlert() {
    await Modals.alert({
      title: "No Internet Connection",
      message: "Please check your Internet connection"
    });
  }

  removeListener(): void {
    this.handler.remove();
  }
}
