import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import Api from "@/app/modules/_global/classes/api/Api";

@Module({ namespaced: true })
export default class AppStore extends VuexModule {
  private _data: null | object = null;
  private _buildingComplexData: null | object = null;

  private _language = localStorage.getItem("app_language");
  private _mode = process.env.VUE_APP_MODE as string;

  get data(): null | object {
    return this._data;
  }

  get buildingComplexData(): null | object {
    return this._buildingComplexData;
  }

  get language(): null | string {
    return this._language;
  }

  get mode(): string {
    return this._mode;
  }

  get urlMode(): string {
    let url = process.env.VUE_APP_CORE_URL;
    let urlMode = "Prod";

    if (this._mode == "Dev") url = process.env.VUE_APP_CORE_DEV_URL;
    if (this._mode == "Test") url = process.env.VUE_APP_CORE_TEST_URL;

    if (url?.includes("dev")) urlMode = "Dev";
    if (url?.includes("test")) urlMode = "Test";

    return urlMode;
  }

  @Mutation
  setData(data: object): void {
    this._data = data;
  }

  @Mutation
  setBuildingComplexData(data: object): void {
    this._buildingComplexData = data;
  }

  @Mutation
  setLanguage(data: string): void {
    localStorage.setItem("app_language", data);
    this._language = data;
  }

  @Mutation
  setMode(data: string): void {
    this._mode = data;
  }

  @Action
  changeLanguage(data: object) {
    return Api.get(`/api/translations?language=${data}`);
  }

  @Action
  setTile(id: number) {
    return Api.get(`/api/apps/mobile/tile/${id}`);
  }
}
