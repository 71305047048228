import { Action, Module, Mutation, VuexModule, config } from "vuex-module-decorators";
import Api from "@/app/modules/_global/classes/api/Api";

config.rawError = true;

type Post = {
  id: number;
  url_upload: string;
  name?: string;
  description?: string;
  translation?: {
    name?: string;
    description?: string;
  };
  is_company_post: string;
  show_to: string;
  created_at: string;
  updated_at: string;
};

type PostMeta = {
  currentMode: number;
  currentPage: number;
};

@Module({ namespaced: true })
export default class WhiteboardStore extends VuexModule {
  private _data: null | Post[] = null;
  private _personalData: null | Post[] = null;

  private _lazyLoaded: boolean[] = [];
  private _currentMode = 1 as number;
  private _currentPage = 1 as number;
  private _lastPage: null | number = null;

  get data(): null | Post[] {
    return this._data;
  }

  get personalData(): null | Post[] {
    return this._personalData;
  }

  get lazyLoaded(): boolean[] {
    return this._lazyLoaded;
  }

  get currentMode(): number {
    return this._currentMode;
  }

  get currentPage(): number {
    return this._currentPage;
  }

  get lastPage(): null | number {
    return this._lastPage;
  }

  @Mutation
  setLazyLoaded(data: boolean[]): void {
    this._lazyLoaded = data;
  }

  @Mutation
  setData(data: Post[]): void {
    this._data = data;
  }

  @Mutation
  clearData(): void {
    this._data = null;
    this._lazyLoaded = [];
    this._currentMode = 1;
    this._currentPage = 1;
    this._lastPage = null;
  }

  @Mutation
  setPersonalData(data: Post[]): void {
    this._personalData = data;
  }

  @Mutation
  updatePersonalData(data: Post): void {
    const personalIndex = this._personalData?.findIndex(post => post.id == data.id);

    if (this._personalData && personalIndex != undefined && personalIndex != -1) {
      this._personalData[personalIndex] = {
        id: data.id,
        url_upload: data.url_upload,
        name: data.translation?.name,
        description: data.translation?.description,
        is_company_post: data.is_company_post,
        show_to: data.show_to,
        created_at: data.created_at,
        updated_at: data.updated_at
      };
    }
  }

  @Mutation
  setCurrentMode(data: number): void {
    this._currentMode = data;
  }

  @Mutation
  setCurrentPage(data: number): void {
    this._currentPage = data;
  }

  @Mutation
  setLastPage(data: number): void {
    this._lastPage = data;
  }

  @Action
  fetchAll(data: PostMeta) {
    return Api.get(`/api/posts/apps/list/${data.currentMode}?page=${data.currentPage}`);
  }

  @Action
  fetchPersonal() {
    return Api.get("/api/posts/apps/my-posts");
  }

  @Action
  fetchById(id: number) {
    return Api.get(`/api/posts/apps/${id}`);
  }

  @Action
  store(data: object) {
    return Api.post("/api/posts/apps", data);
  }

  @Action
  update(data: { id: number }) {
    return Api.patch(`/api/posts/apps/${data.id}`, data);
  }

  @Action
  delete(id: number) {
    return Api.delete(`/api/posts/apps/${id}`);
  }

  @Action
  uploadPicture(data: object) {
    return Api.post("/api/uploads/posts", data);
  }
}
