import { Action, Module, Mutation, VuexModule, config } from "vuex-module-decorators";
import Api from "@/app/modules/_global/classes/api/Api";

config.rawError = true;

type Order = {
  id: number;
};

@Module({ namespaced: true })
export default class OrdersStore extends VuexModule {
  private _data: null | Order[] = null;

  get data(): null | Order[] {
    return this._data;
  }

  @Mutation
  setData(data: Order[]): void {
    this._data = data;
  }

  @Action
  fetchAll() {
    return Api.get("/api/orders/apps");
  }

  @Action
  fetchById(id: number) {
    return Api.get(`/api/orders-customer/${id}`);
  }

  @Action
  cancel(data: { id: number }) {
    return Api.patch(`/api/orders/apps/${data.id}`, data);
  }
}
