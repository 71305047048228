export default {
  path: "posts",
  component: () => import("@/app/layouts/ContentLayout.vue"),

  children: [
    {
      path: "",
      name: "posts",
      component: () => import("@/app/modules/whiteboard/pages/Posts.vue")
    },

    {
      path: "personal",
      name: "personal.posts",
      component: () => import("@/app/modules/whiteboard/pages/PersonalPosts.vue")
    },

    {
      path: "personal/:id([0-9]*)/edit",
      name: "edit.post",
      component: () => import("@/app/modules/whiteboard/pages/EditPost.vue"),
      props: route => ({
        postId: Number(route.params.id)
      })
    },

    {
      path: "create",
      name: "create.post",
      component: () => import("@/app/modules/whiteboard/pages/CreatePost.vue")
    },

    {
      path: ":id([0-9]*)",
      name: "post",
      component: () => import("@/app/modules/whiteboard/pages/Post.vue"),
      props: route => ({
        postId: Number(route.params.id)
      })
    }
  ]
};
