import { AxiosResponse } from "axios";

import vuetify from "@/plugins/vuetify";
import router from "@/app/router";
import store from "@/app/store";

const VALID_STATUSES = [200, 201, 202, 203, 204, 300, 301, 302, 303, 304];

export default class ApiHandler {
  static checkResponse(response: AxiosResponse) {
    if (VALID_STATUSES.includes(response.status)) {
      return Promise.resolve(response);
    }

    return Promise.reject(new Error(response.statusText));
  }

  static handleError(error: AxiosResponse) {
    if (error.request) {
      switch (error.request.status) {
        case 400:
          // Bad request
          this._showErrorPage(error);
          break;

        case 401:
          // Unauthorized
          router.push({ name: "logout" }).catch(e => e);
          break;

        case 403:
          // Forbidden
          router.push({ name: "home" }).catch(e => e);
          break;

        case 404:
          // Not found
          this._showErrorPage(error);
          break;

        case 422:
          // Unprocessable Entity (form validation errors)
          vuetify.framework.goTo(0);
          break;

        case 500:
          // Internal Server Error
          this._showErrorPage(error);
          break;

        case 503:
          // Service Unavailable
          this._showErrorPage(error);
          break;

        case 504:
          // Gateway Timeout
          this._showErrorPage(error);
          break;

        default:
          // Network Error
          this._showErrorPage(error);
          break;
      }
    } else {
      this._showErrorPage(error);
    }

    return Promise.reject(error);
  }

  private static _showErrorPage(error: AxiosResponse): void {
    router
      .push({
        name: "error",
        params: { error: error.request }
      })
      .catch(e => e);
  }

  private static _showSnackbar(error: string): void {
    store.commit("snack/setSnack", {
      status: "error",
      text: error
    });
  }
}
