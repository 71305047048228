import vuetify from "@/plugins/vuetify";

type AppTheme = {
  top_bar_color: string;
  top_bar_font_color: string;
  top_bar_icons_color: string;
  tiles_color: string;
  tiles_icon_color: string;
  tiles_font_color: string;
  notruf_tile_color: string;
  notruf_tile_icon_color: string;
  notruf_tile_font_color: string;
  bottom_bar_color: string;
  bottom_bar_icons_color: string;
  bottom_bar_font_color: string;
  buttons_color: string;
  buttons_font_color: string;
  links_color: string;
};

export default class Vuetify {
  constructor(layout: AppTheme) {
    this.setLayout(layout);
  }

  setLayout(layout: AppTheme): void {
    vuetify.framework.theme.themes.light = {
      ...vuetify.framework.theme.themes.light,

      // Default primary color
      primary: `#${layout.tiles_color}`,
      anchor: `#${layout.links_color}`,

      topBarColor: layout.top_bar_color,
      topBarFontColor: layout.top_bar_font_color,
      topBarIconsColor: layout.top_bar_icons_color,

      tilesColor: layout.tiles_color,
      tilesIconColor: layout.tiles_icon_color,
      tilesFontColor: layout.tiles_font_color,

      notrufTileColor: layout.notruf_tile_color,
      notrufTileIconColor: layout.notruf_tile_icon_color,
      notrufTileFontColor: layout.notruf_tile_font_color,

      bottomBarColor: layout.bottom_bar_color,
      bottomBarIconsColor: layout.bottom_bar_icons_color,
      bottomBarFontColor: layout.bottom_bar_font_color,

      buttonsColor: layout.buttons_color,
      buttonsFontColor: layout.buttons_font_color,
      linksColor: layout.links_color
    };
  }
}
