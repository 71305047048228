import { Route } from "vue-router";

export default {
  path: "tile",
  component: () => import("@/app/layouts/FixedContentLayout.vue"),

  children: [
    {
      // How to make it recursive:
      // https://stackoverflow.com/questions/54342485/vuejs-how-to-define-routes-for-any-number-of-optional-parameters-in-vue-router

      path: ":tile([0-9]*)",
      name: "tile",
      component: () => import("@/app/modules/tile/pages/Tile.vue"),
      props: (route: Route) => ({
        tileId: Number(route.params.tile)
      })
    }
  ]
};
