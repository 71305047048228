import { Module, Mutation, VuexModule } from "vuex-module-decorators";

@Module({ namespaced: true })
export default class ContentStore extends VuexModule {
  private _imposeContent: null | object = null;

  get imposeContent(): null | object {
    return this._imposeContent;
  }

  @Mutation
  setImposeContent(data: object): void {
    this._imposeContent = data;
  }
}
