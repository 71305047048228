import { Action, Module, Mutation, VuexModule, config } from "vuex-module-decorators";
import Api from "@/app/modules/_global/classes/api/Api";

config.rawError = true;

@Module({ namespaced: true })
export default class NewsStore extends VuexModule {
  private _upcoming = true;

  get upcoming(): boolean {
    return this._upcoming;
  }

  @Mutation
  setUpcoming(filter: boolean): void {
    this._upcoming = filter;
  }

  @Action
  fetchResourceSchedule(data: { id: number; date_from: string }) {
    return Api.get(`/api/booking/schedule/resource/${data.id}?date_from=${data.date_from}`);
  }

  @Action
  fetchMyReservations() {
    return Api.get("/api/booking/apps/my-reservations");
  }

  @Action
  fetchReservation(data: { reservation_id: number; person_id: number }) {
    return Api.get(`/api/booking/reservation/${data.reservation_id}?person_id=${data.person_id}`);
  }

  @Action
  storeReservation(data: object) {
    return Api.post("/api/booking/reservation", data);
  }

  @Action
  updateReservation(data: { id: number; body: object }) {
    return Api.patch(`/api/booking/reservation/${data.id}`, data.body);
  }

  @Action
  cancelReservation(id: number) {
    return Api.patch(`/api/booking/apps/reservation/cancel/${id}`);
  }
}
