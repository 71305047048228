export default {
  path: "profile",
  component: () => import("@/app/layouts/FixedContentLayout.vue"),

  children: [
    {
      path: "",
      name: "profile",
      component: () => import("@/app/modules/profile/pages/Profile.vue")
    },

    {
      path: "personal-interests",
      name: "personal-interests",
      component: () => import("@/app/modules/profile/pages/PersonalInterests.vue")
    },

    {
      path: "change-password",
      name: "change-password",
      component: () => import("@/app/modules/profile/pages/ChangePassword.vue")
    }
  ]
};
