export default {
  path: "",
  component: () => import("@/app/layouts/FixedContentLayout.vue"),

  children: [
    {
      path: "",
      component: () => import("@/app/modules/home/pages/Home.vue"),
      name: "home"
    }
  ]
};
