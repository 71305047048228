export const login = {
  path: "login",
  name: "login",
  component: () => import("@/app/modules/auth/pages/Login.vue"),

  meta: {
    title: "AUTH.LOGIN"
  }
};

export const forgotPassword = {
  path: "forgot-password",
  name: "forgot-password",
  component: () => import("@/app/modules/auth/pages/ForgotPassword.vue"),

  meta: {
    title: "AUTH.FORGOT_PASSWORD"
  }
};

export const register = {
  path: "register",
  name: "register",
  component: () => import("@/app/modules/auth/pages/Register.vue"),

  meta: {
    title: "AUTH.REGISTER"
  }
};

export const logout = {
  path: "/logout",
  name: "logout",
  component: () => import("@/app/modules/auth/components/Logout.vue"),

  meta: {
    requiresAuth: true
  }
};
