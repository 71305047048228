export default {
  path: "settings",
  component: () => import("@/app/layouts/FixedContentLayout.vue"),

  children: [
    {
      path: "",
      name: "settings",
      component: () => import("@/app/modules/settings/pages/Settings.vue")
    }
  ]
};
