import { Module, Mutation, VuexModule } from "vuex-module-decorators";

@Module({ namespaced: true })
export default class ApiFetchingStore extends VuexModule {
  private _apiFetching: boolean = false as boolean;

  get isApiFetching(): boolean {
    return this._apiFetching;
  }

  @Mutation
  setApiFetching(status: boolean): void {
    this._apiFetching = status;
  }
}
