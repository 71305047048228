import { Module, Mutation, VuexModule } from "vuex-module-decorators";

@Module({ namespaced: true })
export default class SnackStore extends VuexModule {
  snackMessage: null | object = null;

  @Mutation
  setSnack(data: null | object): void {
    this.snackMessage = data;
  }
}
