import { DeviceInfo, DeviceLanguageCodeResult, Plugins } from "@capacitor/core";
import store from "@/app/store";

export default class Device {
  screenWidth: number;
  screenHeight: number;

  constructor() {
    this.screenWidth = screen.width;
    this.screenHeight = screen.height;

    this.getDeviceInfo().then(deviceInfo => {
      store.commit("device/setDeviceInfo", deviceInfo);
    });
  }

  get getDeviceScreenSize(): object {
    return {
      width: this.screenWidth,
      height: this.screenHeight
    };
  }

  getDeviceInfo(): Promise<DeviceInfo> {
    return Plugins.Device.getInfo();
  }

  getLanguageCode(): Promise<DeviceLanguageCodeResult> {
    return Plugins.Device.getLanguageCode();

    // return new Promise((resolve, reject) => {
    //   Plugins.Device.getLanguageCode()
    //     .then(data => resolve(data))
    //     .catch(error => reject(error));
    // });
  }
}
