import { Action, Module, Mutation, VuexModule, config } from "vuex-module-decorators";
import Api from "@/app/modules/_global/classes/api/Api";

config.rawError = true;

type News = {
  id: number;
  name: string;
  description: string;
  reading_date: number;
};

@Module({ namespaced: true })
export default class NewsStore extends VuexModule {
  private _data: null | News[] = null;

  get data(): null | News[] {
    return this._data;
  }

  @Mutation
  setData(data: News[]): void {
    this._data = data;
  }

  @Mutation
  setAsRead(data: News): void {
    const item = this._data?.find(i => i.id == data.id);
    if (item) item.reading_date = Date.now();
  }

  @Action
  fetch() {
    return Api.get("/api/users/notifications");
  }

  @Action
  update(data: News) {
    return Api.patch("/api/users/notifications", data);
  }
}
