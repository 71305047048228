import { Action, Module, VuexModule, config } from "vuex-module-decorators";
import Api from "@/app/modules/_global/classes/api/Api";

config.rawError = true;

@Module({ namespaced: true })
export default class FormStore extends VuexModule {
  @Action
  send(data: object) {
    return Api.post("/api/reports/report", data);
  }
}
