import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import goTo from "vuetify/es5/services/goto";

// Store
import store from "@/app/store";

// Classes
import App from "@/app/modules/_global/classes/App";

// Route modules
import home from "@/app/modules/home/router";
import tile from "@/app/modules/tile/router";
import news from "@/app/modules/news/router";
import orders from "@/app/modules/orders/router";
import posts from "@/app/modules/whiteboard/router";
import profile from "@/app/modules/profile/router";
import settings from "@/app/modules/settings/router";
import notruf from "@/app/modules/notruf/router";
import booking from "@/app/modules/booking/router";
import { login, logout, forgotPassword, register } from "@/app/modules/auth/router";
import { error, welcomeContent, imposeContent } from "@/app/modules/_global/router";

// Layouts
import MainLayout from "@/app/layouts/MainLayout.vue";
import AuthLayout from "@/app/layouts/AuthLayout.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    component: MainLayout,

    beforeEnter: (to, from, next) => {
      App.init({ auth: true }).finally(next);
    },

    meta: {
      requiresAuth: true
    },

    children: [home, tile, news, orders, posts, profile, settings, notruf, booking]
  },

  {
    path: "/auth",
    component: AuthLayout,

    beforeEnter: (to, from, next) => {
      // if (store.getters["auth/hasApiToken"]) next({ name: "home" });

      App.init({ auth: false }).finally(next);
    },

    children: [login, forgotPassword, register]
  },

  welcomeContent,
  imposeContent,
  logout,
  error
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,

  // https://router.vuejs.org/guide/advanced/scroll-behavior.html
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      setTimeout(() => {
        return new Promise(resolve => {
          resolve(goTo(savedPosition.y, { duration: 0 }));
        });
      }, 10);
    } else {
      goTo(0);
    }
  }
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!store.getters["auth/hasApiToken"]) {
      next({ name: "login" });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
