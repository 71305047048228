export default {
  path: "orders",
  component: () => import("@/app/layouts/FixedContentLayout.vue"),

  children: [
    {
      path: "",
      name: "orders",
      component: () => import("@/app/modules/orders/pages/Orders.vue")
    },

    {
      path: ":id([0-9]*)",
      name: "order",
      component: () => import("@/app/modules/orders/pages/Order.vue"),
      props: route => ({
        orderId: Number(route.params.id)
      })
    }
  ]
};
