import { GoogleAnalytics } from "@ionic-native/google-analytics";

import store from "@/app/store";

export default class GA {
  static _Instance = GoogleAnalytics;

  static async startTrackerWithId(id: string) {
    await GA._Instance.startTrackerWithId(id, 10);
    // await GA._Instance.debugMode();
  }

  static async setUserId(id: string) {
    await GA._Instance.setUserId(id);
  }

  static async addCustomDimension(key: number, value: string) {
    const platform = store.getters["device/deviceInfo"]?.platform;
    if (platform != "web") await GA._Instance.addCustomDimension(key, value);
  }

  static async trackView(title: string) {
    const platform = store.getters["device/deviceInfo"]?.platform;
    if (platform != "web") await GA._Instance.trackView(title);
  }

  static async trackEvent(category: string, action: string, label?: string) {
    const platform = store.getters["device/deviceInfo"]?.platform;
    if (platform != "web") await GA._Instance.trackEvent(category, action, label);
  }
}
