export default {
  path: "notruf",
  component: () => import("@/app/layouts/FixedContentLayout.vue"),

  children: [
    {
      path: "",
      name: "notruf",
      component: () => import("@/app/modules/notruf/components/Notruf.vue")
    }
  ]
};
