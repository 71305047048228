import Vue from "vue";
import Vuetify from "vuetify/lib";

import de from "vuetify/src/locale/de";
import en from "vuetify/src/locale/en";
import fr from "vuetify/src/locale/fr";

import "roboto-fontface/css/roboto/roboto-fontface.css";
import "@mdi/font/css/materialdesignicons.css";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {
      customProperties: true
    },
    themes: {
      light: {
        primary: "#5AB45C",
        secondary: "#424242",
        accent: "#82B1FF",
        error: "#FF5252",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#FFC107"
      }
    }
  },
  icons: {
    iconfont: "mdi"
  },
  lang: {
    locales: { de, en, fr }
  }
});
