export default {
  path: "news",
  component: () => import("@/app/layouts/FixedContentLayout.vue"),

  children: [
    {
      path: "",
      name: "news",
      component: () => import("@/app/modules/news/pages/News.vue")
    }
  ]
};
