import { Action, Module, Mutation, VuexModule, config } from "vuex-module-decorators";
import Api from "@/app/modules/_global/classes/api/Api";

config.rawError = true;

@Module({ namespaced: true })
export default class PersonalInterestsStore extends VuexModule {
  private _data: null | object = null;

  get data(): null | object {
    return this._data;
  }

  @Mutation
  setData(data: object): void {
    this._data = data;
  }

  @Action
  fetch() {
    return Api.get("/api/tags");
  }

  @Action
  userTags() {
    return Api.get("/api/users/tags");
  }

  @Action
  update(data: object) {
    return Api.patch("/api/users/tags", data);
  }
}
